@import './_fonts.scss';

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

button {
  cursor: pointer;
}

.mantine-Container-root {
  min-height: 100vh;
  padding: 0;
}

#html5qr-code-full-region {
  width: 50%;
}

#reader__dashboard_section button {
  padding: 8px;
  margin: 8px 0;
  font-size: 16px;
}

.bottom-icon {
  flex-direction: column;
}

.mantine-Notification-root:last-of-type {
  margin-bottom: 60px;
}

.mantine-Notification-closeButton {
  color: #868e96;
}

.none-pointer {
  pointer-events: none;
}

.mantine-PasswordInput-invalid {
  color: #eb5757;
  border-color: #eb5757;
}
